import styled from 'styled-components'

export const LinkSection = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0 20px;
  }

  > div {
    background: #f8f4ec;
    text-align: center;
    padding: 0;

    a {
      font-weight: 300;
    }

    p {
      color: #b13a04;
      font-size: 14px;
    }
  } 
`;

export const LeftSection = styled.div`
  display: inline-block;
  width: calc(50% - 1px);
  vertical-align: top;

  @media only screen and (max-width: 550px) {
    width: 100%;
  }

  img {
    width: ${props => props.sizedimage || "auto"} !important;
    height: ${props => props.sizedimage || "auto"} !important;
    border-radius: ${props => props.sizedimage ? "50%" : "0"};
    margin: ${props => props.sizedimage ? "20px 10px 20px 10px !important" : "0"};
  }
`;

export const RightSection = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  padding: 10px 0;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 550px) {
    height: auto;
    width: 100%;
    text-align: center;
  }

  h3 {
    margin-bottom: 10px;
  }

  p {
    padding-right: 10px;
    padding-bottom: 50px;

    @media only screen and (max-width: 550px) {
      padding-left: 10px;
    }
  }
`;

export const HomeButton = styled.button`
    background: #b13a04;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    bottom: 10px;
    right: 0;

    &:hover {
      background-color: #76360b;
    }

    a {
      color: #fcf8e5;
      padding: 10px;
      display: inline-block;
      font-weight: 300;
    }

`;