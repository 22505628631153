import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { LinkSection, RightSection, LeftSection, HomeButton } from '../components/standardtile'
import { HighlightText } from '../components/generalstyling'
import Header from '../components/header'
import { Helmet } from "react-helmet"
import { ImageFull } from '../components/imageelements'
import { MainBodySection } from '../components/generalstyling'
import { AdContainer } from '../components/adstyling'
import AdBanner from '../components/adbanner'



// markup
const ExperiencePage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>See, Hear and Experience Opera | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/experience" />
          <meta name="description" content="There's nothing like seeing, hearing and experiencing opera live. Learn about how and where you can do so, including ways of getting cheap tickets." />
        </Helmet>
        <Hero title="Experience Opera" subtitle="Where to see and hear opera in venues around the world"/>
        <MainBodySection>
                    
          <Header title="Where to see and hear operas" />

          <HighlightText>Opera only really takes shape in performance. There are a great many places where you can see and hear opera around the world.</HighlightText>

          <p>The vast majority of operatic performance occurs in buildings built for the purpose, many of them on the grandest of scales.</p>

          <p>Traditional opera houses, most of which are to be found in Europe, tend to be opulent places like “La Scala” in Milan and the “Palais Garnier” in Paris. However, the opera house has frequently been seen as a status symbol for a city and nation which has led to many architecturally incredible opera houses being built in modern times, “Sydney Opera House” perhaps the most famous. </p>
          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <Header title="Opera Houses"/>

          <p>Opera houses are venues with large orchestra pits and big stages (the operas demand it!). They generally seat upwards of 1500 people, the Metropolitan Opera House in New York seating just less than 4000. Unlike most regular theatres, opera house auditoria are built in a horseshoe shape for acoustical reasons. The downside to this are the dreadful sightlines of many seats, and the sides at the top are invariably where the cheapest seats are to be found.</p>

          <ImageFull>
            <StaticImage src="../images/operahouses/glyndebourne_auditorium.jpeg" alt="The horseshoe shaped auditorium of Glyndebourne Festival Opera"/>
            <figcaption>The horseshoe shaped auditorium of <Link to="/operahouses/glyndebourne">Glyndebourne Festival Opera</Link></figcaption>
          </ImageFull>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>           

          <Header title="Attending the opera"/>
          
          <p>There’s a common misconception that attending opera requires observing a secret collection of elite protocols that only those on the inside can comprehend. This is complete and utter garbage. Attending the opera is no different than attending a musical or play.</p>

          <p>There's a good deal of variation between opera houses, though for the most part they're all very approachable. Few have a dress code but the majority still do not let latecomers in. Below are guides and FAQs to some of the most important opera houses around the world.</p>

          <LinkSection>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operahouses/bayreuth"><StaticImage src="../images/operahouses/bayreuth.jpg" alt="The Bayreuth Festspielhaus"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operahouses/bayreuth">Bayreuth</Link></h3>

                <p>The Bayreuth Festival, founded by Richard Wagner and devoted to his works. An opera festival like no other.</p>

                <HomeButton><Link to="/operahouses/bayreuth">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operahouses/glyndebourne"><StaticImage src="../images/operahouses/glyndebourne.jpg" alt="Glyndebourne Opera House"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operahouses/glyndebourne">Glyndebourne</Link></h3>

                <p>The Glyndebourne Festival, running each Summer from May to August. A country mansion with a world class opera house tacked on the side.</p>

                <HomeButton><Link to="/operahouses/glyndebourne">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operahouses/metopera"><StaticImage src="../images/operahouses/metopera.jpg" alt="Metropolitan Opera House"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operahouses/metopera">Metropolitan Opera</Link></h3>

                <p>The Metropolitan Opera (known usually as just the "Met") in New York City is the 800 pound gorilla of the operatic world.</p>

                <HomeButton><Link to="/operahouses/metopera">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operahouses/royalopera"><StaticImage src="../images/operahouses/royalopera.jpg" alt="The Royal Opera House"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operahouses/royalopera">Royal Opera House</Link></h3>

                <p>The Royal Opera is one of the world's leading opera companies and the most important in London.</p>

                <HomeButton><Link to="/operahouses/royalopera">Learn More ></Link></HomeButton>
              </RightSection>
            </div>
          </LinkSection>
        </MainBodySection>
    </Layout>
  )
}

export default ExperiencePage
